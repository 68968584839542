<template>
  <div class="inviteDistributor" id="target-element">
    <van-nav-bar
      title="邀请成为二级分销员"
      left-arrow
      @click-left="onClickLeft"
      class="dark_bg"
    />
    <div class="content">
      <!-- <div class="pointer" id="qrCode" align="center"></div> -->
      <!-- <div id="imgcode"></div> -->
      <!-- 添加一个新的div，用来展示 canvas 转换的 base64 图片 -->
      <div style="text-align: center" class="qrImg">
        <img
          :src="qrcodeimg"
          alt=""
          width="200"
          height="200"
          v-show="qrcodeimg"
          id="qr-img"
        />
      </div>
      <!-- <van-button @click="copyToClipboard(qrCodeBase64)" plain type="info"
        >复制二维码</van-button
      > -->
      <div class="downLoadQr">
        <!-- <van-button @click="copyImageToClipboard(qrcodeimg)" plain type="info"
          >复制二维码链接</van-button
        > -->
        <div class="text">请截图保存</div>
      </div>
    </div>
  </div>
</template>

<script>
// import QRCode from "qrcodejs2";
import QRCode from "qrcode";
// import Clipboard from "clipboard";
// import VueClipboard from "vue-clipboard2";
// import html2canvas from "html2canvas";

export default {
  name: "",
  components: {
  },
  props: {},
  data() {
    return {
      qrcodeimg: "",
      qrCodeBase64: "",
      deviceEnvir: this.Base.deviceEnvir(),
      wx: this.is_weixn(),
    };
  },
  created() {},
  mounted() {
    // this.generateQrCode();
    // setTimeout(() => {
    //   this.generateQrCode();
    // }, 100); //100代表 100毫秒 即0.1s

    setTimeout(() => {
      this.createQr();
    }, 100); //100代表 100毫秒 即0.1s
  },
  
  methods: {
    createQr() {
      let distributionStoreId = this.$route.query.distributionStoreId
      let getStoreId = localStorage.getItem("storeId");
      let storeId = getStoreId && getStoreId != 'null' ? getStoreId : 0;
      if (process.env.VUE_APP_BASE_API == "/api") {
        // this.urlPrefix = `${process.env.VUE_APP_BASE_API}/#/distributorApply`
        // this.urlPrefix = 'http://192.168.31.16:8082/#/inviteDistributor'
        
        // if(storeId) {

        // }
        // this.urlPrefix = window.location.origin + "/#/distributorApply?parentId=" + distributionStoreId + '&storeId=' + storeId;

        this.urlPrefix = `${window.location.origin}/#/distributorApply?parentId=${distributionStoreId}&storeId=${storeId}`;
      } else {
        // this.urlPrefix = `${process.env.VUE_APP_BASE_API}/#/distributorApply?parentId=${distributionStoreId}&storeId=${storeId}`;
        this.urlPrefix = `${window.location.origin}/#/distributorApply?parentId=${distributionStoreId}&storeId=${storeId}`;
      }

      // QRCode.toCanvas(this.urlPrefix, {
      //   errorCorrectionLevel: "H", //容错率L（低）H(高)
      //   margin: 1, //二维码内边距，默认为4。单位px
      //   height: 200, //二维码高度
      //   width: 200, //二维码宽度
      //   scal: 177,
      //   color: {
      //     dark: "#000", // 二维码背景颜色
      //     // light: '#000' // 二维码前景颜色
      //   },
      //   rendererOpts: {
      //     quality: 0.9,
      //   },
      // })
      //   .then((canvas) => {
      //     console.log(canvas);
      //     document.getElementById("qrCode").append(canvas);

      //     // var image = new Image(); //实例一个img
      //     // image.src = canvas.toDataURL("image/png"); //转换成base64格式路径的png图片
      //     this.qrCodeBase64 = canvas.toDataURL("image/png"); //转换成base64格式路径的png图片

      //     console.log('canvas.toDataURL("image/png")',canvas.toDataURL("image/png"));
      //     // image.style.width = "100%"; //设置样式
      //     // console.log('image', image);
      //     // qrCode.appendChild(image); //添加到容器中
      //     // canvas.style.display = "none"; //隐藏掉canvas
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });

      QRCode.toDataURL(this.urlPrefix, {
        errorCorrectionLevel: "H",
        margin: 1,
        height: 200,
        width: 200,
        type: "10",
        scal: 177,
        type: "10",
        color: {
          dark: "#000", // 二维码背景颜色
          // light: '#000' // 二维码前景颜色
        },
        rendererOpts: {
          quality: 0.9,
        },
      })
        .then((url) => {
          // console.log(url);
          this.qrcodeimg = url;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    copyImageToClipboard(base64Data) {
      // 创建一个临时的textarea元素
      const textarea = document.createElement("textarea");
      textarea.style.position = "fixed";
      textarea.style.opacity = 0;

      // 将base64图片数据赋值给textarea的value属性
      textarea.value = base64Data;

      // 将textarea添加到DOM中
      document.body.appendChild(textarea);

      // 选中textarea中的内容
      textarea.select();

      try {
        // 复制选中的内容到剪贴板
        const successful = document.execCommand("copy");
        const message = successful ? "图片已成功复制到剪贴板" : "复制图片失败";
        if (successful) {
          this.$toast({
            message: message,
            type: "success",
          });
        } else {
          this.$toast({
            message: message,
            type: "fail",
          });
        }

        console.log(message);
      } catch (error) {
        console.log("复制图片失败", error);
      }

      // 移除临时的textarea元素
      document.body.removeChild(textarea);
    },
    is_weixn() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    downloadImage() {
      // console.log('设备', this.deviceEnvir, this.wx);
      // if(this.wx) {
      //   this.$toast({
      //     message: "WEICHAT",
      //     type: "success",
      //   });
      //   return
      // }

      // 创建下载链接
      var a = document.createElement("a");
      // a.href = this.qrcodeimg;

      // // 设置下载属性
      // a.download = "二维码";

      // // 模拟点击下载链接以触发下载操作
      // a.click();

      const ua = navigator.userAgent;
      if (ua.indexOf("Trident") !== -1 && ua.indexOf("Windows") !== -1) {
        // IE内核 并且  windows系统 情况下 才执行;
        var bstr = atob(this.qrcodeimg.split(",")[1]);
        var n = bstr.length;
        var u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        const blob = new Blob([u8arr]);
        window.navigator.msSaveOrOpenBlob(blob, "下载" + "." + "png");
      } else if (ua.indexOf("Firefox") > -1) {
        // 火狐兼容下载
        const blob = this.base64ToBlob(this.qrcodeimg); //new Blob([content]);
        const evt = document.createEvent("HTMLEvents");
        evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
        a.download = " "; //下载图片名称，如果填内容识别不到，下载为未知文件，所以我这里就不填为空
        a.href = URL.createObjectURL(blob);
        a.dispatchEvent(
          new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );
      } else {
        // 谷歌兼容下载
        // img.src = myCanvas[0].toDataURL('image/jpg')
        a.href = this.qrcodeimg;
        // 设置下载文件的名字
        a.download = "二维码";
        // 点击
        a.click();

        this.$toast({
          message: "下载成功",
          type: "success",
        });
      }
    },

    onClickLeft() {
      this.$router.back();
    },
  },
  computed: {},
  watch: {},
};
</script>
<style lang="less" scoped>
.inviteDistributor {
  width: 100vw;
  height: 100vh;
  background: #fff;
} /*光标呈现为指示链接的指针（一只手）*/
.pointer:hover {
  cursor: pointer;
}
.qrImg {
  margin: 80px 0 30px;
}
.downLoadQr {
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  font-size: 0.24rem /* 12/50 */;
  .text {
    margin-top: 10px;
  }
}
</style>
